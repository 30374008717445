/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  height: 8vh;
}

.navbar-brand {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.hamburger-menu {
  padding: 1rem;
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.navbar-menu {
  display: flex;
  flex-direction: row;
  background-color: #333;
  width: 100vw;
  justify-content: space-evenly;
}

.navbar-menu a {
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;
  padding: 0.5rem;
}
