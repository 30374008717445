.personalTop video {
  width: 100vw;
  border-bottom: 5px solid black;
  box-shadow: 2px 2px 5px black;
}

.personalTopContainer {
  position: relative;
}

.personal-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

.calc-wrapper,
.quiz-app-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
