.Main {
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  width: 100vw;
  height: 100vh;
}

.welcomePageVideo {
  display: flex;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.enter {
  width: 100vw;
  position: absolute;
  left: -2vw;
  bottom: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.personalPortfolioLink,
.professionalServicesLink,
.bar {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  text-decoration: none;
  font-size: 3.5vh;
  color: white;
  padding: 0.5vw;
}

@media screen and (max-width: 720px) {
  .personalPortfolioLink,
  .professionalServicesLink,
  .bar {
    font-size: 4vw;
  }
}
